import React from 'react';
import PropTypes from 'prop-types';
import ic_search from '../assets/img/setting/ic_search.svg';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    const { customStyle, placeholder } = this.props;
    const { value } = this.state;

    return (
      <div className="search-container" style={customStyle}>
        <img src={ic_search} alt="search" />
        <input
          type="text"
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder || "搜尋"}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  onChange: PropTypes.func,
  customStyle: PropTypes.object,
  placeholder: PropTypes.string
};

export default SearchBar;
