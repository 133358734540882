import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import SearchBar from '../SearchBar';
import ic_add from "../../assets/img/setting/ic_add.svg";
import ic_edit from "../../assets/img/setting/btn_edit.svg";
import ic_delete from "../../assets/img/setting/ic_trash_r.svg";
import CreateLabelModal from './CreateLabelModal';
import { Modal } from '@mui/material';

const getLighterColor = (hexColor) => {
  // Remove the # if present
  const color = hexColor.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Make each component 20% lighter
  const lighterR = Math.min(255, Math.floor(r + (255 - r) * 0.8));
  const lighterG = Math.min(255, Math.floor(g + (255 - g) * 0.8));
  const lighterB = Math.min(255, Math.floor(b + (255 - b) * 0.8));

  // Convert back to hex
  return `#${lighterR.toString(16).padStart(2, '0')}${lighterG.toString(16).padStart(2, '0')}${lighterB.toString(16).padStart(2, '0')}`;
};

class LabelList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openPopup: false,
      selectedLabel: null,
      search: '',
      labels: [],
      showDeleteConfirm: false,
      labelToDelete: null
    };
  }

  componentDidMount() {
    this.fetchLabels();
  }

  async fetchLabels() {
    const { socket } = this.context;
    socket.emit('getAllLabels', null, (err, data) => {
      if (err) {
        console.error('Error fetching labels:', err);
      } else {
        this.setState({
          labels: data,
        });
      }
    });
  }

  handleDelete = (label, e) => {
    e.stopPropagation();
    this.setState({
      showDeleteConfirm: true,
      labelToDelete: label
    });
  };

  confirmDelete = () => {
    const { socket } = this.context;
    const { labelToDelete } = this.state;

    socket.emit('deleteLabel', { id: labelToDelete.id }, (err) => {
      if (err) {
        console.error('Error deleting label:', err);
      } else {
        this.setState({
          showDeleteConfirm: false,
          labelToDelete: null
        });
        this.fetchLabels();
      }
    });
  };

  render() {
    const { labels, showDeleteConfirm, labelToDelete } = this.state;
    const filteredLabels = labels?.filter(x =>
      x.name.toUpperCase().includes(this.state.search?.trim()?.toUpperCase())
    );

    return (
      <div id="staff-container">
        <div className="top-container">
          <div className="header-row">
            <div className="staff-info">標籤</div>
            <div className="add-button" onClick={() => this.setState({ selectedLabel: null, openPopup: true })}>
              <img src={ic_add} alt="add" />
              <span>新增標籤</span>
            </div>
          </div>
          <SearchBar onChange={(value) => this.setState({ search: value })} customStyle={{ margin: '20px 0 0' }} />
        </div>

        <div className="list-container">
          {filteredLabels?.map((item, index) => (
            <div key={`label_${index}`} className="list">
              <div className="list-item">
                <div
                  className="label-tag"
                  style={{
                    backgroundColor: `${getLighterColor(item.color?.colorCode)}`,
                    color: item.color?.colorCode
                  }}
                >
                  {item.name}
                </div>
              </div>
              <div className="list-item actions">
                <img
                  src={ic_edit}
                  alt="edit"
                  className="action-icon"
                  onClick={() => this.setState({ selectedLabel: item, openPopup: true })}
                />
                <img
                  src={ic_delete}
                  alt="delete"
                  className="action-icon"
                  onClick={(e) => this.handleDelete(item, e)}
                />
              </div>
            </div>
          ))}
        </div>

        <CreateLabelModal
          openPopup={this.state.openPopup}
          item={this.state.selectedLabel}
          onConfirm={() => {
            this.setState({ openPopup: false, selectedLabel: null });
            this.fetchLabels();
          }}
          onCancel={() => this.setState({ openPopup: false, selectedLabel: null })}
        />

        <Modal
          open={showDeleteConfirm}
          onClose={() => this.setState({ showDeleteConfirm: false, labelToDelete: null })}
          className="modal"
        >
          <div style={{ width: 400, padding: 36, borderRadius: 18, backgroundColor: '#fff' }}>
            <div style={{ fontSize: 18, fontWeight: 600, color: '#2c2c2c', marginBottom: '24px', textAlign: 'center' }}>
              確定要刪除標籤「{labelToDelete?.name}」嗎？
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
              <button
                className="modalButton"
                onClick={this.confirmDelete}
                style={{ backgroundColor: '#ff5f5f', color: '#fff' }}
              >
                刪除
              </button>
              <button
                className="modalButton"
                onClick={() => this.setState({ showDeleteConfirm: false, labelToDelete: null })}
                style={{ backgroundColor: '#dedede' }}
              >
                取消
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

LabelList.contextTypes = {
  socket: PropTypes.object.isRequired,
};

export default withRouter(LabelList);
