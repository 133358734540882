import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import SearchBar from '../SearchBar';
import ic_add from "../../assets/img/setting/ic_add.svg";
import ic_edit from "../../assets/img/setting/btn_edit.svg";
import CreateColorModal from './CreateColorModal';

class ColorList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openPopup: false,
      selectedColor: null,
      search: '',
      colors: [],
    };
  }

  componentDidMount() {
    this.fetchColors();
  }

  async fetchColors() {
    const { socket } = this.context;
    socket.emit('getLabelColors', null, (err, data) => {
      if (err) {
        console.error('Error fetching colors:', err);
      } else {
        this.setState({
          colors: data,
        });
      }
    });
  }

  render() {
    const { colors } = this.state;
    const filteredColors = colors?.filter(x =>
      x.colorCode.toUpperCase().includes(this.state.search?.trim()?.toUpperCase())
    );

    return (
      <div id="staff-container">
        <div className="top-container">
          <div className="header-row">
            <div className="staff-info">顏色</div>
            <div className="add-button" onClick={() => this.setState({ selectedColor: null, openPopup: true })}>
              <img src={ic_add} alt="add" />
              <span>新增顏色</span>
            </div>
          </div>
          <SearchBar onChange={(value) => this.setState({ search: value })} customStyle={{ margin: '20px 0 0' }} />
        </div>

        <div className="list-container">
          {filteredColors?.map((item, index) => (
            <div key={`color_${index}`} className="list">
              <div className="list-item id-column">
                {item.id}
              </div>
              <div className="list-item">
                <div
                  className="color-box"
                  style={{ backgroundColor: item.colorCode }}
                />
              </div>
              <div className="list-item actions">
                <img
                  src={ic_edit}
                  alt="edit"
                  className="action-icon"
                  onClick={() => this.setState({ selectedColor: item, openPopup: true })}
                />
              </div>
            </div>
          ))}
        </div>

        <CreateColorModal
          openPopup={this.state.openPopup}
          item={this.state.selectedColor}
          onConfirm={() => {
            this.setState({ openPopup: false, selectedColor: null });
            this.fetchColors();
          }}
          onCancel={() => this.setState({ openPopup: false, selectedColor: null })}
        />
      </div>
    )
  }
}

ColorList.contextTypes = {
  socket: PropTypes.object.isRequired,
};

export default withRouter(ColorList);
