import React from 'react';
import PropTypes from 'prop-types';
import { Modal, TextField, Button } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '32px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 0',
  },
  title: {
    fontSize: '14px',
    color: '#6d6d6d',
    flex: 1,
  },
  modalButton: {
    padding: '8px 27px',
    borderRadius: '10px',
    backgroundColor: '#dedede',
    fontSize: '14px',
    fontWeight: '500',
    color: '#2c2c2c',
    boxShadow: 'none',
    '& .Mui-disabled': {
      opacity: 0.45,
    }
  },
  textInput: {
    flex: 2,
    backgroundColor: '#f6f6f6',
    '& .MuiInputBase-root': {
      fontSize: '14px',
    },
  },
  colorGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '8px',
    flex: 2,
  },
  colorBox: {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1px solid #ddd',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: 'transform 0.2s'
    }
  },
  selectedColor: {
    border: '2px solid #000'
  }
};

class CreateLabelModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      formData: {
        name: '',
        colorId: ''
      },
      colors: []
    };
  }

  componentDidMount() {
    this.fetchColors();
  }

  componentDidUpdate(prevProps) {
    if (this.props.item !== prevProps.item) {
      this.setState({
        formData: {
          name: this.props.item ? this.props.item.name : '',
          colorId: this.props.item ? this.props.item.colorId : ''
        }
      });
    }
  }

  fetchColors = async () => {
    const { socket } = this.context;
    socket.emit('getLabelColors', null, (err, data) => {
      if (err) {
        console.error('Error fetching colors:', err);
      } else {
        this.setState({ colors: data });
      }
    });
  };

  handleSubmit = async () => {
    const { socket } = this.context;
    const { item, onConfirm } = this.props;
    const { formData } = this.state;

    try {
      if (item) {
        socket.emit('updateLabel', {
          id: item.id,
          name: formData.name,
          colorId: formData.colorId
        }, (err) => {
          if (err) throw err;
          onConfirm();
        });
      } else {
        socket.emit('createLabel', formData, (err) => {
          if (err) throw err;
          onConfirm();
        });
      }
    } catch (error) {
      console.error('Error saving label:', error);
    }
  };

  render() {
    const { openPopup, item, onCancel, classes } = this.props;
    const { formData, colors } = this.state;

    return (
      <Modal
        className={classes.modal}
        open={openPopup}
        onClose={onCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{ width: 500, padding: 36, borderRadius: 18, backgroundColor: '#fff' }}>
          <div style={{ fontSize: 18, fontWeight: 600, color: '#2c2c2c', marginBottom: '16px' }}>
            {item ? '編輯標籤' : '新增標籤'}
          </div>
          <div style={{ maxHeight: 450, overflowY: 'scroll' }}>
            <div className={classes.row}>
              <div className={classes.title}>標籤名稱</div>
              <TextField
                variant="outlined"
                size="small"
                className={classes.textInput}
                value={formData.name}
                onChange={(e) => this.setState({
                  formData: { ...formData, name: e.target.value }
                })}
                placeholder="輸入標籤名稱"
              />
            </div>
            <div className={classes.row}>
              <div className={classes.title}>標籤顏色</div>
              <div className={classes.colorGrid}>
                {colors.map((color) => (
                  <div
                    key={color.id}
                    onClick={() => this.setState({
                      formData: { ...formData, colorId: color.id }
                    })}
                    className={`${classes.colorBox} ${formData.colorId === color.id ? classes.selectedColor : ''}`}
                    style={{
                      backgroundColor: color.colorCode
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.modalButton}
              style={{ backgroundColor: '#0059c1', color: '#fff' }}
              onClick={this.handleSubmit}
              disabled={!formData.name.trim() || !formData.colorId}
            >
              確定
            </Button>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={onCancel}
              style={{ marginLeft: '16px' }}
            >
              取消
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

CreateLabelModal.contextTypes = {
  socket: PropTypes.object.isRequired,
};

CreateLabelModal.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  item: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateLabelModal);
