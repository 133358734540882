import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import socketCluster from 'socketcluster-client';
import Cookies from 'universal-cookie';

import config from './config';
import { Auth } from './Auth';
import { connect } from 'react-redux';
import { onUpdateAuth } from './redux/action';

export const SocketContext = createContext({socket: null});
class Socket extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.initSocket();
  }

  getChildContext() {
    return { socket: this.socket };
  }

  initSocket() {
    // console.log('Initiate the connection to the server');
    var options = {
      hostname: config.hostname,
      secure: true,
      port: config.port,
      rejectUnauthorized: false, // Only necessary during debug if using a self-signed certificate
      ackTimeout: 30000, //milliseconds
    };

    // Initiate the connection to the server
    this.socket = socketCluster.create(options);

    // var socket = socketCluster.connect();
    this.socket.on('error', function (err) {
      console.error(err);
    });

    var autoLogin = () => this.autoLogin();
    var updateAuth = (bool) => this.props.onUpdateAuth(bool)

    this.socket.on('connect', function (status) {
      console.log('Socket is connected');
      console.log('Socket isAuthenticated: ' + status.isAuthenticated);
      if (status.isAuthenticated === true) {
      } else {
        if (Auth.rememberMe === true) {
          autoLogin();
        } else {
          localStorage.clear();
        }
      }
      updateAuth(Boolean(status.isAuthenticated))
    });
  }

  autoLogin() {
    const cookies = new Cookies();
    Auth.autoLogin(this.socket, cookies, () => this.props.onUpdateAuth(true));
  }

  render() {
    if (this.props.isAuthenticated != null) return <SocketContext.Provider value={this.getChildContext()}>{this.props.children}</SocketContext.Provider>;
    else return null
  }
}

Socket.childContextTypes = {
  socket: PropTypes.object,
};

const mapStateToProps = (state) => {
  return { isAuthenticated: state.reducer.isAuthenticated }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateAuth: (bool) => {
      dispatch(onUpdateAuth(bool));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Socket);

export const useSocket = () => useContext(SocketContext);
