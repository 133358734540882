import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { SketchPicker } from 'react-color';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '32px',
  },
  modalButton: {
    padding: '8px 27px',
    borderRadius: '10px',
    backgroundColor: '#dedede',
    fontSize: '14px',
    fontWeight: '500',
    color: '#2c2c2c',
    boxShadow: 'none',
    '& .Mui-disabled': {
      opacity: 0.45,
    }
  },
  colorPickerContainer: {
    marginTop: '20px'
  }
};

class CreateColorModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      colorCode: '#000000'
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.item !== prevProps.item) {
      this.setState({
        colorCode: this.props.item ? this.props.item.colorCode : '#000000'
      });
    }
  }

  handleSubmit = async () => {
    const { socket } = this.context;
    const { item, onConfirm } = this.props;
    const { colorCode } = this.state;

    try {
      if (item) {
        socket.emit('updateLabelColor', {
          id: item.id,
          colorCode: colorCode
        }, (err) => {
          if (err) throw err;
          onConfirm();
        });
      } else {
        socket.emit('createLabelColor', {
          colorCode: colorCode
        }, (err) => {
          if (err) throw err;
          onConfirm();
        });
      }
    } catch (error) {
      console.error('Error saving color:', error);
    }
  };

  render() {
    const { openPopup, item, onCancel, classes } = this.props;
    const { colorCode } = this.state;

    return (
      <Modal
        className={classes.modal}
        open={openPopup}
        onClose={onCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{ width: 500, padding: 36, borderRadius: 18, backgroundColor: '#fff' }}>
          <div style={{ fontSize: 18, fontWeight: 600, color: '#2c2c2c', marginBottom: '16px' }}>
            {item ? '編輯顏色' : '新增顏色'}
          </div>
          <div className={classes.colorPickerContainer}>
            <SketchPicker
              color={colorCode}
              onChange={(color) => this.setState({ colorCode: color.hex })}
              disableAlpha
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.modalButton}
              style={{ backgroundColor: '#0059c1', color: '#fff' }}
              onClick={this.handleSubmit}
              disabled={!colorCode}
            >
              確定
            </Button>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={onCancel}
              style={{ marginLeft: '16px' }}
            >
              取消
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

CreateColorModal.contextTypes = {
  socket: PropTypes.object.isRequired,
};

CreateColorModal.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  item: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateColorModal);
