import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './redux/reducer'
import App from './App';

const store = createStore(rootReducer)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"));

serviceWorker.unregister();