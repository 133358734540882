import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import TopBar from '../../components/TopBar';
import LabelList from '../../components/label/LabelList';

class LabelSettings extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { openDrawer } = this.context;

    return (
      <div id="label-setting" className={!openDrawer ? 'openDrawer' : ''}>
        <TopBar title="標籤管理" {...this.props} {...this.context} />
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <LabelList />
        </div>
      </div>
    )
  }
}

LabelSettings.contextTypes = {
  socket: PropTypes.object.isRequired,
  openDrawer: PropTypes.bool,
  setState: PropTypes.func,
};

export default withRouter(LabelSettings);
